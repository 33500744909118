// Step 1: Import React
import Layout from '../components/layout/LayoutComponent'
import React, { useLayoutEffect } from 'react'
import { ScrollMoves } from "../components/gsapFunctions"

// Step 2: Define your component
const IndexPage = (props) => {

  useLayoutEffect(() => {
    let moves = ScrollMoves();
    return () => {
      moves.forEach((move) => {
        move.scrollTrigger.kill();
      })
    }
  })

  return (
    <Layout pageTitle="Inicio" content='' isHome={true}>
      <>
        <div className='sm:container px-5 md:p-0 w-full flex flex-col bg-primary/0 relative z-10 backdrop-blur-sm mx-auto'>
            
          <div className='flex flex-col 2xl:mx-64 sm:mx-72 mx-0 text-center my-20 gap-16 bg-white/0'>
            <div>
                <h3 className='font-bold text-2xl'>NEUROMED & CARDIOMED RICARDO Y COMPAÑIA LIMITADA (NECARMED CIA LTDA).</h3>
                <p className='text-xl text-justify mt-8'>Somos una empresa orientada al sector salud desde nuestra fundación en el año 2010. A lo largo de estos años nos hemos dedicado a la venta, comercialización, distribución, importación y exportación de Insumos, Equipos Médicos e implantes de alta especialidad,  con una clara visión de la responsabilidad social que involucra esta actividad económica.</p>
                <p className='text-xl text-justify mt-8'>Con nuestra amplia experiencia y sólidas alianzas con nuestros proveedores ofrecemos a nuestros clientes productos de alta calidad y tecnología, que satisfacen sus necesidades, distribuyéndolos con altos niveles de planificación en todo el territorio nacional. Además, contamos con una organización moderna y un equipo de profesionales capaces y dedicados, perseverantes en el crecimiento continuo de nuestra empresa.</p>
                <p className='text-xl text-justify mt-8'>Somos una Empresa comprometida en satisfacer las necesidades de nuestros clientes, suministrando la mejor calidad en insumos y equipos médicos para el sector salud dentro de los más elevados principios éticos y de responsabilidad social.</p>
                
            </div>
            <div className='flex flex-col'>
              <h3 className='font-bold text-2xl text-center mb-10'>NUESTRA EMPRESA SE FUNDAMENTA EN 2 PRINCIPIOS ESTRÁTEGICOS</h3>
              <div className='flex flex-col md:flex-row md:gap-20 gap-10 justify-center'>
                <div className='move-right basis-1/2 text-center text-lg bg-secondary/50 rounded-2xl p-5 md:p-10 z-20 relative flex justify-center flex-col shadow-xl'>
                  <h3 className='font-bold text-2xl mb-8 text-center '>Atención personalizada</h3>
                  <p>Somos una empresa lider en brindar atención personalizada y de calidad a los clientes.</p>
                </div>
                <div className='move-left basis-1/2 text-center text-lg bg-secondary/50 rounded-2xl p-5 md:p-10 z-20 relative flex justify-center flex-col shadow-xl'>
                  <h3 className='font-bold text-2xl mb-8 text-center '>Servicios Integrales</h3>
                  <p>Somos una empresa que brind todos los servicios integrales requeridos por nuestros clientes, de manera que garantizamos su entera satisfacción.</p>
                </div>
              </div>
            </div>



            <div className='flex flex-col md:gap-20 gap-10 justify-center'>
                <div className='move-up basis-1/2 text-center text-lg bg-secondary/50 rounded-2xl p-5 z-20 relative flex justify-center flex-col shadow-xl'>
                  <h3 className='font-bold text-2xl mb-4 text-center '>Misión</h3>
                  <p>Nuestra misión es proveer un servicio eficiente, ofreciendo marcas reconocidas a nivel internacional, asegurando la calidad de todas nuestras actividades comerciales para mantener una satisfacción garantizada y relaciones fructíferas con nuestros clientes y proveedores.</p>
                </div>
                <div className='move-up basis-1/2 text-center text-lg bg-secondary/50 rounded-2xl p-5 z-20 relative flex justify-center flex-col shadow-xl'>
                  <h3 className='font-bold text-2xl mb-4 text-center '>Visión</h3>
                  <p>Nuestra vision es ser reconocidos como líderes en el Mercado nacional, por nuestra capacidad para satisfacer los requerimientos de nuestros clients, con productos de calidad e innovadores que llenen sus necesidades.</p>
                </div>
                <div className='move-up basis-1/2 text-center text-lg bg-secondary/50 rounded-2xl p-5 z-20 relative flex justify-center flex-col shadow-xl'>
                  <h3 className='font-bold text-2xl mb-4 text-center '>Nuestro Equipo</h3>
                  <p>Contamos con un Equipo de Técnicos Quirúrgicos altamente capacitados, con la habilidad de proveer asistencia quirúrgica en las salas de cirugías, quienes además cuentan con el conocimiento y la capacidad de promover los productos y servicios que comercialzamos.</p>
                  <p>Nuestro Equipo de Ingenieros está entrenado para ofrecer servicios post-ventas, lo que incrementa la satisfacción del cliente y garantiza la calidad de nuestros servicios.</p>
                </div>
              </div>
          </div>
        </div>
        
      </>
    </Layout>
  )
}
// Step 3: Export your component
export default IndexPage

export const Head = () => (
  <>
    <title>Sobre Nosotros | NecarMed</title>
  </>
)