/* This example requires Tailwind CSS v2.0+ */
import { MailIcon, BadgeCheckIcon } from '@heroicons/react/outline'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { gsap } from "gsap";
import React, { useRef, useLayoutEffect } from 'react'

export default function Example({content = "TABACALERA HERNANDEZ", isHome = true}) {
    
    var description = "El Puente a una óptima rehabilitación";
    const headerTitle = useRef();
    

  return (
    <>
        <div className='grid -z-10 h-full'>
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <StaticImage
            className='-z-10 h-full'
            transformOptions={{ fit: "cover", cropFocus: "attention" }}
            style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
            }}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            aspectRatio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt="Portada"
            
            // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
            src={
            "../../images/portada/portada.jpg"
            }
            formats={["auto", "webp", "avif"]}
        />
        
        <div
            className='bg-black/40 -z-10 h-full'
            style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "center",
            display: "grid",
            zIndex: "10"
            }}
        >
            {/* Any content here will be centered in the component */}
            <div className='flex flex-col py-28 gap-8 h-full justify-center  sm:mx-0'>
                <div className='flex justify-center'>
                    <StaticImage className='rounded-3xl bg-black/20' src='../../images/portada/logo.png' alt='Logo de NecarMed' width={100}/>
                </div>
                <div className='text-center relative z-10'>
                    <div ref={headerTitle} className='font-header font-semibold sm:text-7xl text-5xl italic'>
                        <span className='text-primary' style={{textShadow: "white 0px 3px 2px"}}>NECAR</span>
                        <span className='text-secondary' style={{textShadow: "white 0px 3px 2px"}}>MED</span>
                    </div>
                    {
                        contentHome(isHome, description)
                    }
                </div>
            </div>
        </div>
        </div>
        
    </>
  )
}

function contentHome(isHome, description){
    if(isHome){
        return(
            <>
                <div className='mt-6 text-secondary sm:text-5xl text-3xl italic' style={{textShadow: "white 0px 1px 2px"}}>
                    {description}
                </div>
            </>
        )
    }
}

// interface IHeaderProps{
//     content: string,
//     isHome: boolean
// }