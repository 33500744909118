import * as React from 'react'
import '../../styles/global.css'
//import '../../styles/fonts.css'
import NavBar from './NavBar'
import Header from './Header'
import Footer from './Footer'
import '../../styles/particles.css'
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
//import { useTranslation } from 'react-i18next';

//import './i18n';
//import '../i18n';

//const { t, i18n } = useTranslation("aboutme");
  //const { t } = props;



//import Portada from '../components/Portada'
const Layout = ({pageTitle, children, content, isHome}) => {
    const particlesInit = useCallback(async (engine) => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
      }, []);
    
      const particlesLoaded = useCallback(async (container) => {
        await console.log(container);
      }, []);
  return (
    <>
      <div className='h-full relative bg-primary/40 text-gray-light'>
      
        
        <Header content={content} isHome={isHome}/>
        <NavBar/>
        {children}
        
        <Footer/>
      </div>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
            fpsLimit: 20,
            interactivity: {
            events: {
                onHover: {
                enable: true,
                mode: "repulse",
                },
                resize: true,
            },
            modes: {
                push: {
                quantity: 4,
                },
                repulse: {
                distance: 100,
                duration: 0.4,
                },
            },
            },
            particles: {
            color: {
                value: "#ffffff",
            },
            links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
            },
            collisions: {
                enable: true,
            },
            move: {
                directions: "none",
                enable: true,
                outModes: {
                default: "bounce",
                },
                random: false,
                speed: 1,
                straight: false,
            },
            number: {
                density: {
                enable: true,
                area: 80,
                },
                value: 5,
            },
            opacity: {
                value: 0.1,
            },
            shape: {
                type: "circle",
            },
            size: {
                value: { min: 1, max: 5 },
            },
            },
            detectRetina: true,
        }}
        />
    </>
  )
}
export default Layout

// interface LayoutProps{
//     pageTitle: string;
//     content: string;
//     isHome: boolean;
//     children: React.ReactNode;
// }

export const Head = () => (
    <>
      <title>Hello World</title>
      <meta name="description" content="Hello World" />
    </>
  )