import React from 'react'


export default function Example() {
    
const info = [
    {name: "Dirección", content: ["Frente a Hotel HEX, módulo g-v-13, Bello Horizonte, Managua, Nicaragua"]},
    {name: "Correo Electronico", content: ["info@necarmed.net", "nelsonjoseh@hotmail.com"]},
    {name: "Teléfono", content: ["(505) 2249-9092" , "(305) 218-3519", "(505) 8998-0057"]},
]


  return (
    <div className='bg-black'>
        <div className='md:container mx-auto sm:px-16 px-4 py-4'>
            <div className='flex flex-col lg:flex-row gap-8 xl:gap-16 p-8 flex-wrap justify-center'>
                {
                    info.map((item, index) => (
                        <InfoContainer key={index} name={item.name} content={item.content}/>
                    ))
                }
            </div>
            <h5 className='text-white text-center'>Copyright 2024 © Todos los derechos reservados</h5>
            <h5 className='text-white text-center'>Sitio desarrollado por <b>Victor Escobar</b></h5>
        </div>
    </div>
  )
}

function InfoContainer({name, content}){
    return(
        <div className='text-white basis-1/2 xl:basis-1/4 text-center border-gray-dark border rounded-lg p-6 flex flex-col backdrop-blur-sm relative z-20 move-up'>
            <p className='text-secondary font-bold text-lg'>{name}</p>
            <div className="basis-full flex justify-center flex-col">
                {
                    content.map((item, index) => (
                        <p key={index}>{item}</p>
                    ))
                }
            </div>
        </div>
    )
}